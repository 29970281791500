import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import TableauInfo from "../components/TableauInfo";
import ServiceSolution from "../components/ServiceSolution";
import NavigateBlock from "../components/NavigateBlock";
import Testimonials from "../components/Testimonials.jsx";
import BlogGrid from "../components/BlogGrid.jsx";
import NavigateBlockV2 from "../components/NavigateBlockV2.jsx";
import Statistics from "../components/Statistics.jsx";

const PartnerDetail = ({ data, location }) => {
    const schema = [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Sedin Technologies",
            url: "https://sedintechnologies.com/",
            sameAs: [
                "https://www.facebook.com/SedinTechnologies",
                "https://twitter.com/SedinTech",
                "https://www.instagram.com/lifeatsedin/",
                "https://www.linkedin.com/company/sedin-technologies/",
            ],

            address: {
                "@type": "PostalAddress",
                addressLocality: "Chennai",
                postalCode: "600083",
                streetAddress:
                    "# A1/1, 49th Street, 7th Avenue, Ashok Nagar, Chennai, TN, India, 600083",
            },
        },
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home page",
                    item: "https://sedintechnologies.com/",
                },
                {
                    "@type": "ListItem",
                    position: 2,
                    name: "Partners",
                    item: "https://sedintechnologies.com/our-partners/",
                },
                {
                    "@type": "ListItem",
                    position: 3,
                    name: data.contentfulPartners.partnerName,
                    item: `https://sedintechnologies.com${location.pathname}`,
                },
            ],
        },
    ];
    const partnerOgImage = data.contentfulPartners.ogImage?.gatsbyImageData?.images.fallback.src;
    return (
        <>
            <Layout>
                <Seo
                    title={data.contentfulPartners.metaTitle}
                    description={data.contentfulPartners.metaDescription}
                    image={partnerOgImage}
                    schemaMarkup={schema}
                />
                <section className="tableau-hero">
                    <div className="container container--py flex flex-ai-c ">
                        <div className="tableau-hero__text">
                            <h1 className="text-h1 title text-fw-medium text-clr-primary gap-2x">
                                {data.contentfulPartners.title}
                            </h1>
                            <p className="text-p1 description text-fw-regular text-clr-secondary gap-2x">
                                {data.contentfulPartners.description.description}
                            </p>
                            <Link
                                to={`/${data.contentfulPartners.callToActionSlug}/`}
                                className="sdn-cta-btn"
                            >
                                {data.contentfulPartners.callToActionText}
                            </Link>
                        </div>
                    </div>
                </section>
                <section className="tableau-partners">
                    <div className="tableau-partners-wrapper">
                        <div className="container ">
                            <div className="tableau-partners__text">
                                <div className="text-h1  text-fw-medium text-clr-primary gap-2x">
                                    {data.contentfulPartners.clientsHeading}
                                </div>
                                <p className="text-p1 text-fw-regular text-clr-secondary gap-2x">
                                    {data.contentfulPartners.clientsDescription}
                                </p>
                            </div>
                            <div className="tableau-partners__list">
                                {data.contentfulPartners.clientsLogo.map((img, i) => (
                                    <div className="partner-logo" key={i}>
                                        <GatsbyImage
                                            image={img.gatsbyImageData}
                                            alt={img.title}
                                            loading="lazy"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tableau-info-wrap">
                    <div className="container container--mid-v2">
                        <TableauInfo
                            infoHeader={data.contentfulPartners.partnerInfoHeading}
                            infoTitle1={data.contentfulPartners.partnerInfoTitle1}
                            infoTitle2={data.contentfulPartners.partnerInfoTitle2}
                            infoDescription1={data.contentfulPartners.infoDescription}
                            infoDescription2={
                                data.contentfulPartners.partnerInfoDescription2
                                    .partnerInfoDescription2
                            }
                            animationUrl={data.contentfulPartners.partnerInfoAnimation.file.url}
                        />
                    </div>
                </section>
                <section>
                    <div className="container container--mid-v2">
                        <ServiceSolution services={data.contentfulPartners.partnerServices} />
                    </div>
                </section>
                <section>
                    <div className="container container--mid-v2">
                        <NavigateBlock
                            header={data.contentfulPartners.navigateText}
                            description={
                                data.contentfulPartners.navigateDescription.navigateDescription
                            }
                            buttonText={data.contentfulPartners.navigateCallToActionText}
                            buttonLink={data.contentfulPartners.navigateCallToActionSlug}
                        />
                    </div>
                </section>
                <section className="tableau-statistics">
                    <div className="tableau-statistics-wrapper">
                        <div className="container">
                            <Statistics
                                description={true}
                                stats={data.contentfulPartners.statistics}
                            />
                        </div>
                    </div>
                </section>
                <section className="tableau-dasboard">
                    <div className="container container--mid-v2">
                        <h2 className="text-h1 tableau-dasboard__heading text-clr-primary text-fw-medium ">
                            {data.contentfulPartners.partnerDetailHeader}
                        </h2>
                        <p className="text-p2 tableau-dasboard__description text-clr-secondary text-fw-regular">
                            {
                                data.contentfulPartners.partnerDetailDescription
                                    .partnerDetailDescription
                            }
                        </p>
                        <GatsbyImage
                            image={data.contentfulPartners.partnerDeatilImage.gatsbyImageData}
                            alt={data.contentfulPartners.partnerDeatilImage.title}
                            loading="lazy"
                        />
                    </div>
                </section>
                <section>
                    <div className="container container--mid-v2">
                        <Testimonials testimonialData={data.contentfulPartners.testimonial} />
                    </div>
                </section>
                <section>
                    <div className="container container--mid-v2">
                        <BlogGrid relatedBlogs={data.contentfulPartners.blogs} />
                    </div>
                </section>
                <section className="navigate-cta">
                    <div className="container container--last-v2">
                        <NavigateBlockV2
                            button={data.contentfulPartners.footerCallToAction.ctaText}
                            title={data.contentfulPartners.footerCallToAction.header}
                        />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default PartnerDetail;

export const query = graphql`
    query partnerDetail($id: String) {
        contentfulPartners(id: { eq: $id }) {
            title
            description {
                description
            }
            partnerName
            slug
            ogImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            infoDescription {
                raw
            }
            metaTitle
            metaDescription
            callToActionText
            callToActionSlug
            clientsHeading
            clientsDescription
            clientsLogo {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            partnerInfoTitle1
            partnerInfoHeading
            partnerInfoDescription1 {
                partnerInfoDescription1
            }
            partnerInfoTitle2
            partnerInfoDescription2 {
                partnerInfoDescription2
            }
            partnerInfoAnimation {
                file {
                    url
                }
            }
            partnerServices {
                animation {
                    file {
                        url
                    }
                }
                title
                description {
                    description
                }
            }
            navigateText
            navigateDescription {
                navigateDescription
            }
            navigateCallToActionText
            navigateCallToActionSlug
            partnerDetailHeader
            partnerDetailDescription {
                partnerDetailDescription
            }
            partnerDeatilImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            testimonial {
                personName
                personDesignation
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
                profileImage {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    title
                }
            }
            blogs {
                title
                description {
                    description
                }
                readTime
                slug
                blogImage {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    title
                }
            }
            footerCallToAction {
                ctaText
                header
            }
            statistics {
                name
                value
            }
        }
    }
`;
