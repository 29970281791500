import React from "react";
import Lottie from "react-lottie";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";

const TableauInfo = ({
    infoHeader,
    infoTitle1,
    infoTitle2,
    infoDescription1,
    infoDescription2,
    animationUrl,
}) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    };
    return (
        <>
            <h2 className="text-h1 header text-fw-medium text-clr-primary gap-2x">{infoHeader}</h2>
            <div className="tableau-info">
                <div className="tableau-info__content">
                    <div>
                        <h3 className="text-p1  gap-1x text-clr-primary text-fw-medium">
                            {infoTitle1}
                        </h3>
                        <div className="text-p2  text-clr-secondary text-fw-regular">
                            {renderRichText(infoDescription1, options)}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-p1 gap-1x text-clr-primary text-fw-medium">
                            {infoTitle2}
                        </h3>
                        <p className="text-p2  text-clr-secondary text-fw-regular">
                            {infoDescription2}
                        </p>
                    </div>
                </div>
                <div className="tableau-info__animation">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            path: animationUrl,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default TableauInfo;
