import React from "react";
import Lottie from "react-lottie";

const ServiceSolution = ({ services }) => {
    return (
        <>
            <div className="service-solution">
                <div className="service-solution__grid">
                    {services.map((solution, i) => (
                        <div className="service-solution__grid-item" key={i}>
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        path: solution.animation?.file?.url,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    {solution?.title}
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    {solution.description?.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ServiceSolution;
